<template>
  <div class="timer" :class="{active: active}">
    <span class="time">
      {{new Date(time * 1000).toISOString().substr(11, 8)}}
    </span>
    <span class="buttons">
      <a href="" v-if="!active && value === 0" @click.prevent.stop="start()"><b-icon-play-fill class="icon" scale="1.5" /></a>
      <a href="" v-if="!active && value > 0" @click.prevent.stop="resume()"><b-icon-play-fill class="icon" scale="1.5" /></a>
      <a href="" v-if="active"  @click.prevent.stop="pause()"><b-icon-pause-fill class="icon" scale="1.5" /></a>
    </span>
  </div>
</template>

<script>
  import {TaskService} from "@/services/TaskService";

  export default {
    name: 'Timer',
    props: {
      value: Number,
      task: Number,
      week: Number
    },
    data: function() {
      return {
        active: false,
        timer: null,
        service: new TaskService()
      };
    },
    methods: {
      start() {
        this.active = true;
        this.secondTimer();
      },
      pause() {
        this.active = false;
        clearTimeout(this.timer);
      },
      resume() {
        this.active = true;
        this.secondTimer();
      },
      secondTimer () {
        this.$log.info('Timer');
        clearTimeout(this.timer);
        this.timer = setTimeout(this.updateTime, 1000)
      },
      async updateTime() {
        this.$log.info('Incrementing Timer');
        let newTime = this.time + 1;
        this.time = newTime;

        this.$log.info('Updating Task Time');
        await this.service.updateTask({
          id: this.task,
          timeTaken: newTime
        });

        this.$log.info('Initialising Second Timer');
        this.secondTimer();
      }
    },
    computed: {
      time: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .timer {
    min-width: 120px;
  }

  .active {
    color: red;
  }

  .time {
    border: 1px solid black;
    padding: 7px 8px;
  }
  .buttons {
    a {
      display: inline-block;
      padding: 5px 8px;
      background: black;
      .icon {
        color: white;
      }
    }
  }
</style>
