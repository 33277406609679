<template>
  <div class="task" :class="{completed: value.completed}">
    <div class="task-details">
      <div class="extras">
        <div class="label-value client">
          <div class="label">Client</div>
          <div class="value">{{value.client}}</div>
        </div>
        <div class="label-value who">
          <div class="label">Who?</div>
          <div class="value">{{value.who}}</div>
        </div>
      </div>
      <div class="statement">
        <span class="name">{{value.name}}</span>
        <span class="so-that-text">... so that ...</span>
        <span class="so-that">{{value.soThat}}</span>
      </div>
    </div>
    <div class="utils">
      <div class="actions">
        <a v-if="value.completed == 0" class="action-button" href="" @click.prevent.stop="complete()"><b-icon-check-circle scale="1.2" title="Mark as Complete" /></a>
        <a v-if="value.completed == 1" class="action-button" href="" @click.prevent.stop="incomplete()"><b-icon-x-circle scale="1.2" title="Mark as Incomplete" /></a>
      </div>
      <Timer :week="week" :task="value.id" v-model="value.timeTaken"></Timer>
      <div class="actions">
        <a class="action-button" href="" @click.prevent.stop="edit()"><b-icon-pencil-fill scale="1.2" /></a>
        <a class="action-button" href="" @click.prevent.stop="remove()"><b-icon-trash-fill scale="1.2" /></a>
      </div>
    </div>
  </div>
</template>

<script>

import Timer from '@/components/week/Timer';
import {TaskService} from "@/services/TaskService";

export default {
  name: 'Task',
  components: {
    Timer
  },
  props: {
    week: Number,
    value: Object
  },
  data() {
    return {
      editMode: false,
      service: new TaskService()
    };
  },
  methods: {
    edit() {
      this.$emit('update', this.value);
    },
    complete() {
      this.$emit('complete', this.value);
    },
    incomplete() {
      this.$emit('incomplete', this.value);
    },
    remove() {
      this.$emit('remove', this.value);
    }
    // save() {
    //   this.service.updateTask({
    //     id: this.value.id,
    //     who: this.value.who,
    //     client: this.value.client,
    //     name: this.value.name,
    //     soThat: this.value.soThat
    //   })
    //       .then(() => {
    //         this.updated();
    //         this.editMode = false;
    //       })
    //       .catch(() => {});
    // },
    // remove() {
    //   this.service.removeTaskById(this.value.id)
    //       .then(() => {
    //         this.updated();
    //       })
    //       .catch(() => {});
    // },
    // updated() {
    //   this.$emit('updated');
    // }
  },
  watch: {

  }
}
</script>

<style lang="scss" scoped>

.task {

  padding: 10px;
  border-top: 2px dashed $neutral-3;
  display: flex;
  flex-direction: column-reverse;

  &.completed {
    opacity: 0.2;
  }

  &:nth-child(2n+1) {
    background: $neutral-2;
  }

  .task-details {

    .extras {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .label-value {
      font-size: 0.75rem;
      padding: 10px;
      border-right: 2px dashed $neutral-3;
      width: 50%;
      text-align: center;

      &.who {
        border-right: 0;
      }

      .label {
        font-weight: bold;
      }
      .value {
        font-size: 1rem;
      }
    }

    .statement {
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 0 10px;
      .so-that-text {
        display: inline-block;
        margin: 0px 5px;
        font-weight: bold;
        font-style: italic;
      }
    }
  }
  .utils {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actions {
      margin-left: 20px;
      width: 60px;
      .action-button {
        margin: 0 5px;
      }
    }
  }
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {

}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .task {

    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    .task-details {
      display: flex;
      align-items: center;

      .label-value {
        width: 100px;

      }
    }

    .utils {
      margin-bottom: 0px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

}
</style>
