<template>
  <div id="app">
    <MainHeader />
    <router-view></router-view>
  </div>
</template>

<script>
import {initJsStore} from "./services/Database";
import MainHeader from "@/components/ui/Header";

export default {
  name: 'App',
  components: {MainHeader},
  async beforeCreate() {
    try {
      const isDbCreated = await initJsStore();
      if (isDbCreated) {
        this.$log.info('Database Created');
        // prefill database
      } else {
        this.$log.info('Database Opened');
      }
    } catch (ex) {
      this.$log.error('Database Error', ex);
      // Global.isIndexedDbSupported = false;
    }
  }
}
</script>
<style lang="scss">
  @import '~bootstrap';
  @import '~bootstrap-vue';
  @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

  h1 {
    font-family: "Dancing Script", cursive;
  }

  h2 {
    margin-top: 40px;
    margin-bottom: 30px;
    padding: 10px 0;
    border-bottom: 2px dashed $neutral-3;
  }

  h3 {
    padding: 0;
    margin: 0;
  }

  .dialog-actions {
    display: flex;
    justify-content: space-between;
  }

  h1, h2, h3 {
    &:hover {
      .b-icon {
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-name: bounce;
        animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
      }
    }
  }

  @keyframes bounce {
    0%   { transform: scale(1,1)      translateY(0); }
    10%  { transform: scale(1.1,.9)   translateY(0); }
    30%  { transform: scale(.9,1.1)   translateY(-20px); }
    50%  { transform: scale(1.05,.95) translateY(0); }
    57%  { transform: scale(1,1)      translateY(-7px); }
    64%  { transform: scale(1,1)      translateY(0); }
    100% { transform: scale(1,1)      translateY(0); }
  }

</style>
