import { connection } from "./Connection";
import { DATA_TYPE } from "jsstore";

const latestVersion = 3;

export const getSchema = (version) => {
    let tables = [];

    tables.push({
        name: 'Week',
        columns: {
            id: { primaryKey: true, autoIncrement: true },
            name:  { notNull: true, dataType: DATA_TYPE.String },
            createdAt:  { notNull: true, dataType: DATA_TYPE.DateTime }
        },
        version: 1
    });
    tables.push({
        name: 'Task',
        columns: {
            id: { primaryKey: true, autoIncrement: true },
            weekId: { notNull: true, dataType: DATA_TYPE.Number },
            category: { notNull: true, dataType: DATA_TYPE.String },
            who:  { notNull: true, dataType: DATA_TYPE.String },
            client: { notNull: true, dataType: DATA_TYPE.String },
            name:  { notNull: true, dataType: DATA_TYPE.String },
            soThat: { notNull: true, dataType: DATA_TYPE.String },
            timeTaken: { notNull: true, dataType: DATA_TYPE.Number },
            completed:  { notNull: true, dataType: DATA_TYPE.Number }, // 0 = Incomplete, 1 = Complete
            order: { notNull: true, dataType: DATA_TYPE.Number },
            createdAt:  { notNull: true, dataType: DATA_TYPE.DateTime }
        },
        version: 1
    });
    tables.push({
        name: 'RetroItem',
        columns: {
            id: { primaryKey: true, autoIncrement: true },
            weekId: { notNull: true, dataType: DATA_TYPE.Number },
            category: { notNull: true, dataType: DATA_TYPE.String },
            details:  { notNull: true, dataType: DATA_TYPE.String },
            order: { notNull: true, dataType: DATA_TYPE.Number },
            createdAt:  { notNull: true, dataType: DATA_TYPE.DateTime }
        },
        version: 1
    });

    if(version >= 2) {
        tables.push({
            name: 'Accomplishment',
            columns: {
                id: { primaryKey: true, autoIncrement: true },
                weekId: { notNull: true, dataType: DATA_TYPE.Number },
                category: { notNull: true, dataType: DATA_TYPE.String },
                details:  { notNull: true, dataType: DATA_TYPE.String },
                order: { notNull: true, dataType: DATA_TYPE.Number },
                createdAt:  { notNull: true, dataType: DATA_TYPE.DateTime }
            },
            version: 2
        });
    }

    if(version >= 3) {
        tables.push({
            name: 'SectionState',
            columns: {
                id: { primaryKey: true, autoIncrement: true },
                section: { notNull: true, dataType: DATA_TYPE.String },
                category: { notNull: true, dataType: DATA_TYPE.String },
                state:  { notNull: true, dataType: DATA_TYPE.Number } // 0 = closed, 1 = open
            },
            version: 3
        });
    }

    return {
        name: "Week_Planner",
        tables: tables
    };
};

async function upgradeSchema() {
    console.log('upgrading database');
    let version = await connection.getDbVersion('Week_Planner');
    let schema = getSchema(version);
    let tableData = {};

    for (let i = 0; i < schema.tables.length; i++) {
        try {
            tableData[schema.tables[i].name] = await connection.select({
                from: schema.tables[i].name
            });
        }
        catch(e) {
            console.log(e)
        }
    }


    let latestSchema = getSchema(latestVersion);
    var isDbCreated = await connection.initDb(latestSchema);
    if(isDbCreated) {
        for (let key in tableData) {
            // eslint-disable-next-line no-prototype-builtins
            if(tableData.hasOwnProperty(key)) {
                try {
                    await connection.insert({
                        into: key,
                        data: tableData[key]
                    })
                }
                catch(e) {
                    console.log(e)
                }
            }
        }
        return isDbCreated;
    }


}

export const initJsStore = async () => {
    let version = await connection.getDbVersion('Week_Planner');
    if(version === 0) {
        version = latestVersion;
    }
    let schema = getSchema(version);
    let isDbCreated = await connection.initDb(schema);
    if(isDbCreated) {
        return isDbCreated;
    }
    else {
        if(version < latestVersion) {
            return await upgradeSchema(latestVersion);
        }
        else {
            return isDbCreated;
        }
    }
};