<template>
    <div class="dialog">
        <transition name="transitionOverlay">
            <div class="dialog-overlay" v-if="active"></div>
        </transition>
        <transition name="transitionDialog">
            <div class="dialog-content" v-if="active">
                <slot>Please enter some content into the dialog.</slot>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        active: {}
    },
    data: function() {
        return {

        }
    },
    methods: {

    },
    computed: {

    }
};
</script>

<style lang="scss" scoped>
.dialog-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    z-index: 100000;
}
.dialog-content {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    background: #FFFFFF;
    width: 100%;
    max-width: 450px;
    border-left: 1px solid #CCCCCC;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    z-index: 100001;
}

.transitionOverlay-enter-active, .transitionOverlay-leave-active {
    transition: all 500ms;
}

.transitionOverlay-enter, .transitionOverlay-leave-to {
    opacity: 0;
}

.transitionDialog-enter-active, .transitionDialog-leave-active {
    transition: all 500ms;
}

.transitionDialog-enter, .transitionDialog-leave-to {
    right: -100%;
}
</style>
