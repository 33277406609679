<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <div class="title">
          <h1>Weeks</h1>
          <div class="page-actions">
            <b-button @click.prevent.stop="openCreateDialog()" type="button" size="sm" variant="success">Create New Week</b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <p>Below are the weeks that you have so far filled as part of the planner, this allows you to create and review historical weeks.</p>

        <b-row>
          <b-col md="6" lg="4" v-for="(week, $index) in weeks" :key="$index">
            <div class="week">
              <div class="inner">
                <router-link :to="weekRoute(week.id)" class="name"><b-icon-calendar-week /> {{ week.name }}</router-link>
                <div class="actions">
                  <a href="" @click.prevent.stop="openUpdateDialog(week)"><b-icon-pencil-fill /></a>
                  <a href="" @click.prevent.stop="remove(week)"><b-icon-trash-fill /></a>
                </div>
              </div>
            </div>
          </b-col>

        </b-row>

        <dialog-container :active="createDialog.active">
          <dialog-form :submit="create">
            <dialog-header>Create New Week</dialog-header>
            <dialog-body>
              <b-form-group
                  id="field-week"
                  label="Week Name"
                  label-for="week-name"
                  description="The name of the week you want to create."
              >
                <b-form-input
                    id="week-name"
                    v-model="createDialog.model.name"
                    type="text"
                    placeholder="e.g. 2021-03-01"
                    required
                    autofocus
                ></b-form-input>
              </b-form-group>
            </dialog-body>
            <dialog-footer>
              <div class="dialog-actions">
                <b-button @click.prevent.stop="closeCreateDialog()" type="button" variant="light">Close</b-button>
                <b-button type="submit" variant="success">Create</b-button>
              </div>
            </dialog-footer>
          </dialog-form>
        </dialog-container>

        <dialog-container :active="updateDialog.active">
          <dialog-form :submit="update">
            <dialog-header>Create New Week</dialog-header>
            <dialog-body>
              <b-form-group
                  id="field-week"
                  label="Week Name"
                  label-for="week-name"
                  description="The name of the week you want to create."
              >
                <b-form-input
                    id="week-name"
                    v-model="updateDialog.model.name"
                    type="text"
                    placeholder="e.g. 2021-03-01"
                    required
                    autofocus
                ></b-form-input>
              </b-form-group>
            </dialog-body>
            <dialog-footer>
              <div class="dialog-actions">
                <b-button @click.prevent.stop="closeUpdateDialog()" type="button" variant="light">Close</b-button>
                <b-button type="submit" variant="success">Create</b-button>
              </div>
            </dialog-footer>
          </dialog-form>
        </dialog-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import { WeekService } from "@/services/WeekService";

  export default {
    name: 'Weeks',
    props: {
      title: String
    },
    mounted() {
      this.getWeeks();
    },
    data: function() {
      return {
        createDialog: {
          active: false,
          model: {
            name: '',
          }
        },
        updateDialog: {
          active: false,
          model: {
            id: '',
            name: '',
          }
        },

        weeks: [],
        service: new WeekService()
      };
    },
    methods: {
      weekRoute(id) {
        return '/week/'+ id;
      },
      getWeeks() {
        this.service.getWeeks().then((data) => {
          this.weeks = data;
        })
        .catch(() => {
          console.log('error');
        });
      },
      openCreateDialog() {
        this.createDialog.active = true;
      },
      closeCreateDialog() {
        this.createDialog.active = false;
        this.createDialog.model = {
          name: '',
        };
      },
      async create() {
        await this.service.addWeek({
          createdAt: new Date(),
          name: this.createDialog.model.name
        });
        this.getWeeks();
        this.closeCreateDialog();
      },
      openUpdateDialog(week) {
        this.updateDialog.active = true;
        this.updateDialog.model.id = week.id;
        this.updateDialog.model.name = week.name;
      },
      closeUpdateDialog() {
        this.updateDialog.active = false;
        this.updateDialog.model = {
          id: '',
          name: '',
        };
      },
      update() {
        this.service.updateWeek({
          id: this.updateDialog.model.id,
          name: this.updateDialog.model.name
        })
            .then(() => {
              this.getWeeks();
              this.closeUpdateDialog();
            })
            .catch((error) => {
              console.log('TODO error message for creation of week.', error);
            });
      },
      async remove(week) {
        await this.service.removeWeekById(week.id);

        this.getWeeks();
      }
    }
  }
</script>

<style lang="scss" scoped>
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .week {
    padding: 10px;

    .inner {
      display: flex;
      justify-content: space-between;
      background: $neutral-2;
      padding: 20px;
      border: 2px dashed $neutral-4;
    }
    a {
      font-size: 1.2em;
    }

    .actions {
      a {
        margin: 5px;
        font-size: 1rem;
      }
    }
  }
</style>
