<template>
  <div class="accomplishment">
    {{ item.details }}

    <div class="item-actions">
      <a class="action-button" href="" @click.prevent.stop="editItem(item)"><b-icon-pencil-fill /></a>
      <a class="action-button" href="" @click.prevent.stop="removeItem(item)"><b-icon-trash-fill /></a>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Accomplishment',
  props: {
    icon: String,
    value: Object
  },
  data() {
    return {};
  },
  methods: {
    editItem(item) {
      this.$emit('update', item);
    },
    removeItem(item) {
      this.$emit('remove', item);
    }
  },
  computed: {
    item: {
      set(val) {
        this.$emit('input', val);
      },
      get() {
        return this.value;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.accomplishment {
  width: 100%;
  padding: 10px;
  border-top: 2px dashed #D9D9D9;

  display: flex;
  justify-content: space-between;
}

.item-actions {
  white-space: nowrap;
}

.action-button {
  margin: 5px;
}
</style>
