<template>
  <div class="day" :class="{active: active}">
    <div class="title">
      <h3 @click.prevent.stop="toggleActive">
        <b-icon-chevron-down v-if="!active"></b-icon-chevron-down>
        <b-icon-chevron-up v-if="active"></b-icon-chevron-up>
        {{ category }}
      </h3>
      <div class="title-actions">
        <b-button @click.prevent.stop="openCreateDialog()" type="button" variant="success" size="sm">Add Item</b-button>
      </div>
    </div>

    <div class="items">
      <p>Today I will accomplish 100% of:</p>
      <vue-draggable v-model="tasks" draggable=".task" @end="onDragEnd" @add="onDragAdd" group="tasks">
        <Task class="task" v-for="(item, $index) in tasks" :week="week" v-model="tasks[$index]" :key="$index" @update="openUpdateDialog" @remove="remove" @complete="complete"  @incomplete="incomplete"></Task>
      </vue-draggable>
    </div>

    <dialog-container :active="createDialog.active">
      <dialog-form :submit="create">
        <dialog-header>Create Task</dialog-header>
        <dialog-body>
          <b-form-group
              id="field-task-client"
              label="Client"
              label-for="task-client"
              description="What client is this task being complete for?"
              autofocus
          >
            <b-form-input
                id="task-client"
                type="text"
                v-model="createDialog.model.client"
                placeholder=""
                required
            ></b-form-input>
          </b-form-group>
          <b-form-group
              id="field-task-who"
              label="Who?"
              label-for="task-who"
              description="Who should be listening?"
          >
            <b-form-input
                id="task-who"
                type="text"
                v-model="createDialog.model.who"
                placeholder=""
                required
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="field-task-task"
              label="Task"
              label-for="task-task"
              description="Enter the information about the task here."
          >
            <b-form-input
                id="task-task"
                type="text"
                v-model="createDialog.model.name"
                placeholder=""
                required
            ></b-form-input>
          </b-form-group>
          <b-form-group
              id="field-task-so-that"
              label="So That"
              label-for="task-so-that"
              description="Why is this task being complete? Who will take it on next?"
          >
            <b-form-input
                id="task-so-that"
                type="text"
                v-model="createDialog.model.soThat"
                placeholder=""
                required
            ></b-form-input>
          </b-form-group>
        </dialog-body>
        <dialog-footer>
          <div class="dialog-actions">
            <b-button @click.prevent.stop="closeCreateDialog()" type="button" variant="light">Close</b-button>
            <b-button type="submit" variant="success">Save & Create Another</b-button>
          </div>
        </dialog-footer>
      </dialog-form>
    </dialog-container>

    <dialog-container :active="updateDialog.active">
      <dialog-form :submit="update">
        <dialog-header>Edit Task</dialog-header>
        <dialog-body>
          <b-form-group
              id="field-task-client"
              label="Client"
              label-for="task-client"
              description="What client is this task being complete for?"
          >
            <b-form-input
                id="task-client"
                type="text"
                v-model="updateDialog.model.client"
                placeholder=""
                required
                autofocus
            ></b-form-input>
          </b-form-group>
          <b-form-group
              id="field-task-who"
              label="Who?"
              label-for="task-who"
              description="Who will find this task being complete valuable?"
          >
            <b-form-input
                id="task-who"
                type="text"
                v-model="updateDialog.model.who"
                placeholder=""
                required
            ></b-form-input>
          </b-form-group>

          <b-form-group
              id="field-task-task"
              label="Task"
              label-for="task-task"
              description="Enter the information about the task here."
          >
            <b-form-input
                id="task-task"
                type="text"
                v-model="updateDialog.model.name"
                placeholder=""
                required
            ></b-form-input>
          </b-form-group>
          <b-form-group
              id="field-task-so-that"
              label="So That"
              label-for="task-so-that"
              description="Why is this task being complete? Who will take it on next?"
          >
            <b-form-input
                id="task-so-that"
                type="text"
                v-model="updateDialog.model.soThat"
                placeholder=""
                required
            ></b-form-input>
          </b-form-group>
        </dialog-body>
        <dialog-footer>
          <div class="dialog-actions">
            <b-button @click.prevent.stop="closeUpdateDialog()" type="button" variant="light">Close</b-button>
            <b-button type="submit" variant="success">Save Changes</b-button>
          </div>
        </dialog-footer>
      </dialog-form>
    </dialog-container>
  </div>
</template>

<script>
import Task from '@/components/week/Task';
import {TaskService} from "@/services/TaskService";
import {SectionStateService} from "@/services/SectionStateService";

export default {
  name: 'Day',
  created() {
    this.getTasks();
    this.getState();
  },
  components: {
    Task
  },
  props: {
    category: String,
    week: Number
  },
  data: function () {
    return {
      tasks: [],
      createDialog: {
        active: false,
        model: {
          who: '',
          client: '',
          name: '',
          soThat: ''
        }
      },
      updateDialog: {
        active: false,
        model: {
          id: '',
          who: '',
          client: '',
          name: '',
          soThat: ''
        }
      },
      sectionName: 'week-' + this.week + '-day',
      service: new TaskService(),
      sectionService: new SectionStateService(),
      sectionState: null
    };
  },
  computed: {
    active() {
      if(this.sectionState === null) {
        return true;
      }

      return this.sectionState.state === 1;
    }
  },
  methods: {
    async getState() {
      let states = await this.sectionService.getSectionState(this.sectionName, this.category);
      if(states.length > 0) {
        this.sectionState = states[0];
      }
    },
    async getTasks() {
      this.$log.info('Loading Tasks');
      this.tasks = await this.service.getTasksByCategory(this.week, this.category);
      this.$log.info('Tasks Loaded');
    },
    openCreateDialog() {
      this.createDialog.active = true;
    },
    closeCreateDialog() {
      this.createDialog.active = false;
      this.createDialog.model = {
        who: '',
        client: '',
        name: '',
        soThat: ''
      };
    },
    async create() {
      this.$log.info('Create Task');
      await this.service.addTask({
        createdAt: new Date(),
        weekId: this.week,
        category: this.category,
        who: this.createDialog.model.who,
        client: this.createDialog.model.client,
        name: this.createDialog.model.name,
        soThat: this.createDialog.model.soThat,
        timeTaken: 0,
        order: this.tasks.length,
        completed: 0
      });
      this.createDialog.model = {
        who: '',
        client: '',
        name: '',
        soThat: ''
      };

      await this.getTasks();
    },
    openUpdateDialog(item) {
      this.updateDialog.active = true;
      this.updateDialog.model = {
        id: item.id,
        who: item.who,
        client: item.client,
        name: item.name,
        soThat: item.soThat
      };
    },
    closeUpdateDialog() {
      this.updateDialog.active = false;
      this.updateDialog.model = {
        id: '',
        who: '',
        client: '',
        name: '',
        soThat: ''
      };
    },
    async update() {
      this.$log.info('Update Task');
      await this.service.updateTask(this.updateDialog.model);
      await this.getTasks();
      this.closeUpdateDialog();
    },
    async remove(item) {
      this.$log.info('Remove Task');
      await this.service.removeTaskById(item.id);
      await this.getTasks();
    },
    async complete(item) {
      this.$log.info('Mark task as complete');
      await this.service.updateTask({
        id: item.id,
        completed: 1
      });
      await this.getTasks();
    },
    async incomplete(item) {
      this.$log.info('Mark task as incomplete');
      await this.service.updateTask({
        id: item.id,
        completed: 0
      });
      await this.getTasks();
    },
    async onDragAdd() {
      for(let i = 0; i < this.tasks.length; i++) {
        this.tasks[i].order = i;
        await this.service.updateTask({
          id: this.tasks[i].id,
          category: this.category,
          order: i
        });
      }
    },
    async onDragEnd() {
      this.$log.info('On Drag End - Update Order of Tasks');
      for(let i = 0; i < this.tasks.length; i++) {
        this.tasks[i].order = i;

        await this.service.updateTask({
          id: this.tasks[i].id,
          order: i
        });
      }
    },
    toggleActive() {
      this.updateSectionState(!this.active);
    },
    async updateSectionState(state) {
      if(this.sectionState != null) {
        await this.sectionService.updateSectionState({
          id: this.sectionState.id,
          section: this.sectionName,
          category: this.category,
          state: (state) ? 1 : 0
        });
      }
      else {
        await this.sectionService.addSectionState({
          section: this.sectionName,
          category: this.category,
          state: (state) ? 1 : 0
        });
      }

      await this.getState();
    }
  }
}
</script>

<style lang="scss" scoped>
.day {
  border: 2px dashed $neutral-4;
  margin-bottom: 20px;

  .title {
    background: $neutral-2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }

  .items {
    background: $neutral-1;
    padding: 0 10px;
    overflow: hidden;
    transition: height 1000ms;
    transition-delay: 1000ms;
    height: 0;
  }

  &.active {
    .items {
      padding: 10px;
      height: initial;
      // transform: translateY(0);
    }
  }
}
</style>
