import {connection} from "./Connection";
import {TaskService} from "@/services/TaskService";
import {RetroItemService} from "@/services/RetroItemService";
import {AccomplishmentService} from "@/services/AccomplishmentService";

export class WeekService {

    constructor() {
        this.tableName = 'Week';
        this.taskService = new TaskService();
        this.retroItemService = new RetroItemService();
        this.accomplishmentService = new AccomplishmentService();
    }

    getWeeks() {
        return connection.select({
            from: this.tableName,
            order: {
                by: 'createdAt',
                type: 'desc'
            }
        });
    }

    getWeekById(id) {
        return connection.select({
            from: this.tableName,
            where: {
                id: parseInt(id, 10)
            }
        });
    }

    updateWeek(data) {
        return connection.update({
            in: this.tableName,
            set: {
                name: data.name
            },
            where: {
                id: data.id
            }
        });
    }

    addWeek(data) {
        return connection.insert({
            into: this.tableName,
            values: [data],
            return: true
        });
    }

    async removeWeekById(id) {
        const removeStatus = await connection.remove({
            from: this.tableName,
            where: {
                id: id
            }
        });

        await this.accomplishmentService.removeByWeekId(id);
        await this.taskService.removeByWeekId(id);
        await this.retroItemService.removeByWeekId(id);

        return removeStatus
    }
}