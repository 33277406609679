<template>
    <div class="dialog-body">
        <slot></slot>
    </div>
</template>

<script>
export default {
    data: function() {
        return {

        }
    },
    methods: {

    },
    computed: {

    }
};
</script>

<style lang="scss" scoped>
.dialog-body {
    padding: 30px;
    overflow: auto;
    height: 100%;
}

</style>
