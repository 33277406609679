<template>
    <form class="dialog-form" v-on:submit.prevent.stop="submit">
        <slot></slot>
    </form>
</template>

<script>
export default {
    props: {
        submit: {}
    },
    data: function() {
        return {

        }
    },
    methods: {

    },
    computed: {

    }
};
</script>

<style lang="scss" scoped>
.dialog-form {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}

</style>
