import {connection} from "./Connection";
import {getSchema} from "@/services/Database"

export class ExportImportService {
    constructor() {

    }

    async exportAll() {
        let tableData = {};
        let version = await connection.getDbVersion('Week_Planner');
        const schema = getSchema(version);
        for (let i = 0; i < schema.tables.length; i++) {
            try {
                tableData[schema.tables[i].name] = await connection.select({
                    from: schema.tables[i].name
                });
            }
            catch(e) {
                console.log(e)
            }
        }
        return tableData;
    }

    async import(data) {
        try {
            let jsonData = JSON.parse(data);
            if(typeof jsonData === 'object') {
                for(let key in jsonData) {
                    // eslint-disable-next-line no-prototype-builtins
                    if(jsonData.hasOwnProperty(key)) {

                        // Update the createdAt field datatype.
                        for(var i = 0; i < jsonData[key].length; i++) {
                            if(typeof jsonData[key][i].createdAt !== 'undefined') {
                                jsonData[key][i].createdAt = new Date(jsonData[key][i].createdAt);
                            }
                        }

                        await connection.insert({
                            into: key,
                            values: jsonData[key]
                        });
                    }
                }
            }
        }
        catch(e) {
            console.error(e);
            alert('An error occurred importing the data, please check the data and try again.');
        }


        return data;
    }
}