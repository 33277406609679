<template>
  <b-container fluid>
    <b-row>
      <b-col>

        <div v-if="loaded">
          <h1>{{week.name}}</h1>

          <b-row>
            <b-col>
              <h2><b-icon-joystick /> Retro</h2>
              <p>Add your retro items to be announced from last week here, ideally you should fill this out through the week.</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6"><RetroItems icon="b-icon-emoji-laughing" :week="week.id" category="Good"></RetroItems></b-col>
            <b-col md="6"><RetroItems icon="b-icon-emoji-frown" :week="week.id" category="Bad"></RetroItems></b-col>
            <b-col md="6"><RetroItems icon="b-icon-emoji-wink" :week="week.id" category="Better"></RetroItems></b-col>
            <b-col md="6"><RetroItems icon="b-icon-trophy" :week="week.id" category="Best"></RetroItems></b-col>
          </b-row>

          <b-row>
            <b-col>
              <h2><b-icon-star /> Weekly Accomplishments</h2>
              <p>Between one and three accomplishments you want to pull out from last week and this week.</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6"><Accomplishments icon="b-icon-calendar-week" :week="week.id" category="Last Week"></Accomplishments></b-col>
            <b-col md="6"><Accomplishments icon="b-icon-calendar-week" :week="week.id" category="This Week"></Accomplishments></b-col>
          </b-row>

          <b-row>
            <b-col>
              <h2><b-icon-list-task /> Tasks</h2>
              <p>The tasks you are wanting to complete for the week.</p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <Day :week="week.id" category="Monday"></Day>
              <Day :week="week.id" category="Tuesday"></Day>
              <Day :week="week.id" category="Wednesday"></Day>
              <Day :week="week.id" category="Thursday"></Day>
              <Day :week="week.id" category="Friday"></Day>
            </b-col>
          </b-row>
        </div>
        <div v-if="!loaded">Loading</div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Accomplishments from '@/components/week/Accomplishments';
import RetroItems from '@/components/week/RetroItems';
import Day from '@/components/week/Day';
import {WeekService} from "@/services/WeekService";

export default {
  name: 'Week',
  components: {
    Accomplishments,
    RetroItems,
    Day
  },
  mounted() {
    this.loadWeek();
  },
  data() {
    return {
      loaded: false,
      saveTimeout: null,
      week: {
        name: '',
        retroItems: {},
        tasks: {}
      },
      service: new WeekService()
    };
  },
  methods: {
    addWeek() {
      console.log('addWeek');
    },
    async loadWeek() {
      this.$log.info('Starting to Load Week');
      this.loaded = false;
      let data = await this.service.getWeekById(this.$route.params.id)
      if(data.length > 0) {
        this.week = data.pop();
        this.loaded = true;
        this.$log.info('Week Loaded');
      }
      else {
        this.$log.error('Week could not be found');
      }
    },
    reset() {

    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.categories {
  display: flex;
  flex-wrap: wrap;

  .category {
    width: 50%;
  }
}
</style>