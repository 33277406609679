<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <h1>Data Import / Export</h1>

        <h2>Export</h2>
        <p>By clicking the button below you will generate a full export of the data from the database.</p>
        <b-button @click.prevent.stop="exportAction()" type="button" variant="success">Export Data</b-button>
        <textarea class="export-data" v-if="exportedData !== ''" v-model="exportedData" />

        <h2>Import</h2>
        <p>Add the JSON exported data into the area below and click the import button to import the data.</p>
        <textarea class="import-data" v-model="importData" />
        <b-button @click.prevent.stop="importAction()" type="button" variant="success">Import</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import {ExportImportService} from "@/services/ExportImportService";

  export default {
    name: 'Data',
    props: {},
    created() {

    },
    data: function() {
      return {
        importData: '',
        exportedData: '',
        service: new ExportImportService()
      };
    },
    methods: {
      async importAction() {
        await this.service.import(this.importData);
        this.$router.push('/');
      },
      async exportAction() {
        let data = await this.service.exportAll();
        this.exportedData = JSON.stringify(data, null, 2);
      }
    }
  }
</script>

<style lang="scss" scoped>
  .export-data,
  .import-data{
    width: 100%;
    height: 400px;
  }
</style>
