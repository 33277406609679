<template>
  <div class="dialog-header">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    data: function() {
      return {

      }
    },
    methods: {

    },
    computed: {

    }
  };
</script>

<style lang="scss" scoped>
  .dialog-header {
    padding: 10px;
    border-bottom: 1px solid #CCCCCC;
    background: #E9E9E9;
  }

</style>