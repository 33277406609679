import {connection} from "./Connection";

export class AccomplishmentService {

    constructor() {
        this.tableName = 'Accomplishment';
    }

    getAccomplishmentsByWeek(week, category) {
        return connection.select({
            from: this.tableName,
            where: {
                weekId: parseInt(week, 10),
                category
            },
            order: {
                by: 'order',
                type: 'asc'
            }
        });
    }

    getAccomplishmentById(id) {
        return connection.select({
            from: this.tableName,
            where: {
                id: parseInt(id, 10)
            }
        });
    }

    updateAccomplishment(data) {
        let saveData = {};
        for(let key in data) {
            // eslint-disable-next-line no-prototype-builtins
            if(data.hasOwnProperty(key)) {
                if(key !== 'id') {
                    saveData[key] = data[key];
                }
            }
        }

        return connection.update({
            in: this.tableName,
            set: saveData,
            where: {
                id: data.id
            }
        });
    }

    addAccomplishment(data) {
        return connection.insert({
            into: this.tableName,
            values: [data],
            return: true
        });
    }

    removeAccomplishmentById(id) {
        return connection.remove({
            from: this.tableName,
            where: {
                id: parseInt(id, 10)
            }
        });
    }

    async removeByWeekId(id) {
        return await connection.remove({
            from: this.tableName,
            where: {
                weekId: parseInt(id, 10)
            }
        });
    }
}