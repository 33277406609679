import VueDraggable from 'vuedraggable';
import DialogContainer from "@/components/ui/dialog/DialogContainer";
import DialogHeader from "@/components/ui/dialog/DialogHeader";
import DialogBody from "@/components/ui/dialog/DialogBody";
import DialogFooter from "@/components/ui/dialog/DialogFooter";
import DialogForm from "@/components/ui/dialog/DialogForm";

export default function(Vue) {
  Vue.component('vue-draggable', VueDraggable);
  Vue.component('dialog-container', DialogContainer);
  Vue.component('dialog-header', DialogHeader);
  Vue.component('dialog-body', DialogBody);
  Vue.component('dialog-footer', DialogFooter);
  Vue.component('dialog-form', DialogForm);
}