<template>
  <div class="retro-item">
    {{ item.details }}

    <div class="item-actions">
      <a class="action-button" href="" @click.prevent.stop="editItem(item)"><b-icon-pencil-fill /></a>
      <a class="action-button" href="" @click.prevent.stop="removeItem(item)"><b-icon-trash-fill /></a>
    </div>

  </div>
</template>

<script>
  import {RetroItemService} from "@/services/RetroItemService";

  export default {
    name: 'RetroItem',
    props: {
      icon: String,
      value: Object
    },
    data() {
      return {
        service: new RetroItemService()
      };
    },
    methods: {
      editItem(item) {
        this.$emit('update', item);
      },
      removeItem(item) {
        this.$emit('remove', item);
      }
      // async update() {
      //   await this.service.updateRetroItem({
      //     id: this.item.id,
      //     details: this.item.details
      //   });
      //   this.$emit('updated');
      // },
      // async remove() {
      //   await this.service.removeRetroItemById(this.item.id);
      //   this.$emit('updated');
      // }
    },
    computed: {
      item: {
        set(val) {
          this.$emit('input', val);
        },
        get() {
          return this.value;
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.retro-item {
  width: 100%;
  padding: 10px;
  border-top: 2px dashed #D9D9D9;

  display: flex;
  justify-content: space-between;
}

.item-actions {
  white-space: nowrap;
}

.action-button {
  margin: 5px;
}
</style>
