import {connection} from "./Connection";

export class SectionStateService {

    constructor() {
        this.tableName = 'SectionState';
    }

    getSectionState(section, category) {
        return connection.select({
            from: this.tableName,
            where: {
                section,
                category
            }
        });
    }

    updateSectionState(data) {
        let saveData = {};
        for(let key in data) {
            // eslint-disable-next-line no-prototype-builtins
            if(data.hasOwnProperty(key)) {
                if(key !== 'id') {
                    saveData[key] = data[key];
                }
            }
        }

        return connection.update({
            in: this.tableName,
            set: saveData,
            where: {
                id: data.id
            }
        });
    }

    addSectionState(data) {
        return connection.insert({
            into: this.tableName,
            values: [data],
            return: true
        });
    }

    removeSectionState(id) {
        return connection.remove({
            from: this.tableName,
            where: {
                id: parseInt(id, 10)
            }
        });
    }
}