<template>
  <header>

    <router-link to="/" class="logo"><b-icon-calendar-check-fill /> Week Planner</router-link>
    <div class="nav-links">
      <router-link to="/data">Data Import / Export</router-link>
    </div>
  </header>
</template>

<script>
  export default {
    name: 'MainHeader',
    props: {},
    mounted() {

    },
    data: function() {
      return {

      };
    },
    methods: {}
  }
</script>

<style lang="scss" scoped>
header {
  background: #0066CC;

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .logo {
    display: inline-block;
    padding: 10px;
    font-family: 'Dancing Script', cursive;
    font-size: 2.2em;
    color: #FFFFFF;
  }

}

.nav-links {
  padding: 0 20px;
  a {
    color: #FFFFFF;
  }
}
</style>
