<template>
  <div class="retro-items">
    <div class="title">
      <h3><component :is="icon" /> {{ category }}</h3>
      <div class="title-actions">
        <b-button @click.prevent.stop="openCreateDialog()" type="button" variant="success" size="sm">Add Item</b-button>
      </div>
    </div>

    <div class="items">
      <vue-draggable v-model="items" draggable=".retro-item" @end="onDragEnd" @add="onDragAdd" group="retroItems">
        <RetroItem @update="openUpdateDialog(item)" @remove="remove(item)" :icon="icon" v-for="(item, $index) in items" :key="$index" v-model="items[$index]" @updated="retroItemUpdated()"></RetroItem>
      </vue-draggable>
    </div>

    <dialog-container :active="createDialog.active">
      <dialog-form :submit="create">
        <dialog-header>Create Retro Item</dialog-header>
        <dialog-body>
          <b-form-group
              id="field-retro-details"
              label="Details"
              label-for="retro-details"
              description="The details of the retro item you want to add."
          >
            <b-form-input
                id="retro-details"
                type="text"
                v-model="createDialog.model.details"
                placeholder=""
                required
                autofocus
            ></b-form-input>
          </b-form-group>
        </dialog-body>
        <dialog-footer>
          <div class="dialog-actions">
            <b-button @click.prevent.stop="closeCreateDialog()" type="button" variant="light">Close</b-button>
            <b-button type="submit" variant="success">Save & Create Another</b-button>
          </div>
        </dialog-footer>
      </dialog-form>
    </dialog-container>

    <dialog-container :active="updateDialog.active">
      <dialog-form :submit="update">
        <dialog-header>Edit Retro Item</dialog-header>
        <dialog-body>
          <b-form-group
              id="field-retro-details"
              label="Details"
              label-for="retro-details"
              description="The details of the retro item you want to add."
          >
            <b-form-input
                id="retro-details"
                type="text"
                v-model="updateDialog.model.details"
                placeholder=""
                required
                autofocus
            ></b-form-input>
          </b-form-group>
        </dialog-body>
        <dialog-footer>
          <div class="dialog-actions">
            <b-button @click.prevent.stop="closeUpdateDialog()" type="button" variant="light">Close</b-button>
            <b-button type="submit" variant="success">Save Changes</b-button>
          </div>
        </dialog-footer>
      </dialog-form>
    </dialog-container>
  </div>
</template>

<script>
import RetroItem from "@/components/week/RetroItem";
import {RetroItemService} from "@/services/RetroItemService";

export default {
  name: 'RetroItems',
  props: {
    icon: String,
    week: Number,
    category: String
  },
  components: {
    RetroItem
  },
  created() {
    this.getItems();
  },
  data() {
    return {
      items: [],
      createDialog: {
        active: false,
        model: {
          details: ''
        }
      },
      updateDialog: {
        active: false,
        model: {
          id: '',
          details: ''
        }
      },
      model: {
        details: ''
      },
      service: new RetroItemService()
    }
  },
  methods: {
    retroItemUpdated() {
      this.getItems();
    },
    openCreateDialog() {
      this.createDialog.active = true;
    },
    closeCreateDialog() {
      this.createDialog.active = false;
      this.createDialog.model = {
        details: ''
      };
    },
    async create() {
      await this.service.addRetroItem({
        createdAt: new Date(),
        weekId: this.week,
        category: this.category,
        details: this.createDialog.model.details,
        order: 0
      });

      this.createDialog.model.details = '';
      await this.getItems();
    },
    openUpdateDialog(item) {
      this.updateDialog.active = true;
      this.updateDialog.model.id = item.id;
      this.updateDialog.model.details = item.details;
    },
    closeUpdateDialog() {
      this.updateDialog.active = false;
      this.updateDialog.model.id = '';
      this.updateDialog.model.details = '';
    },
    async update() {
      console.log(this.updateDialog.model);
      await this.service.updateRetroItem({
        id: this.updateDialog.model.id,
        details: this.updateDialog.model.details
      });
      await this.getItems();
      this.closeUpdateDialog();
    },
    async remove(item) {

      await this.service.removeRetroItemById(item.id);
      await this.getItems();
    },
    async getItems() {
      this.items = await this.service.getRetroItems(this.week, this.category);
    },
    async onDragAdd() {
      for(let i = 0; i < this.items.length; i++) {
        this.items[i].order = i;
        await this.service.updateRetroItem({
          id: this.items[i].id,
          category: this.category,
          order: i
        });
      }
    },
    async onDragEnd() {
      for(let i = 0; i < this.items.length; i++) {
        this.items[i].order = i;

        await this.service.updateRetroItem({
          id: this.items[i].id,
          order: i
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.retro-items {
  border: 2px dashed $neutral-4;
  background: $neutral-1;
  margin: 5px;

  .title {
    background: $neutral-2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  .items {
    background: $neutral-1;
  }
}
</style>
